import {home} from "./home";
import {services} from "./services";
import {caseStudies} from "./case-studies";
import {about} from "./about";
import {design} from "./design";
import {customweb} from "./customweb";
import {drupalweb} from "./drupalweb";
import {craftweb} from "./craftweb";
import {interfaces} from "./interfaces";
import {mobile} from "./mobile";
import {automation} from "./automation";
import {vrar} from "./vrar";
import {cloudhosting} from "./cloudhosting";
import {emails} from "./emails";
import {optimizely} from "./optimizely";
import {ai} from "./ai";


export const en = {
    meta: {
        home: 'Drupal Development Agency',
        services: 'Our services',
        automation: 'Automation services',
        cloudhosting: 'Cloud Hosting Services (CI/CD)',
        craftweb: 'CraftCMS Web development services',
        customweb: 'Custom Web development services',
        design: 'Design & Creative services',
        drupalweb: 'Drupal Web development services',
        emails: 'Email Journeys services',
        interfaces: 'Custom Interfaces development services',
        mobile: 'Mobile applications development services',
        vrar: 'VR/AR Experiences development services',
        casestudies: 'Case studies',
        casestudies1: 'Case Studies: Webcampus',
        casestudies2: 'Case Studies: JB Conseil',
        casestudies3: 'Case Studies: Haribo',
        casestudies4: 'Case Studies: NSI Solutions (CPA 005)',
        about: 'About us',
        careers: 'Careers',
        whyrollin: 'Why work with us?',
        contact: 'Contact us',
        resources: 'Technical Resources',
        optimizely: 'Optimizely CMS/DXP Development',
        ai: 'Artificial Intelligence services',
    },
    general: {
        "read_more": "Read more",
        "email": "hello",
        "featured": "Featured",
        "interested": "You might also be interested in...",
        "platforms": "Platforms",
        "deliverables": "Deliverables",
        "techstack": "Tech stack",
        "message": "We received your inquiry, we'll get back to you shortly.",
        "error": "Something went wrong, please try again later.",
        "casestudy": "Read case study",
        "notfound": "Not found",
        "notfound1": "The page you are looking for does not exist.",
        "notfound2": "Return to home page",
        "lastupdate": "Last update",
        "search": "Search...",
        "noresults": "There is no result that matches your search.",
        "noresults2": "Return to resources.",
    },
    menu: {
        home: 'Home',
        services: 'Our capabilities',
        about: 'About',
        casestudies: 'Case studies',
        design: 'Design & Creative',
        customweb: 'Custom Web Development',
        drupalweb: 'Drupal Development',
        craftweb: 'Craft CMS Development',
        interfaces: 'Custom Interfaces',
        mobile: 'Mobile Applications',
        automation: 'Automation',
        cloudhosting: 'Cloud infrastructure (CI/CD)',
        vrar: 'VR/AR Experiences',
        emails: 'Email Journeys',
        whyrollin: 'Why Rollin?',
        careers: 'Careers',
        resources: 'Resources',
        contact: 'Contact us',
        optimizely: 'Optimizely CMS/DXP',
        ai: 'Artificial Intelligence',
        access: 'Client access',
    },
    "menutexts": {
        "design": "Data-driven designs that empower your brand voice.",
        "customweb": "Web development services tailored to your custom requirements.",
        "drupalweb": "An enterprise-level CMS for crafting vast, scalable, and high-impact digital solutions.",
        "craftweb": "A powerful alternative to WordPress for small and medium-sized websites.",
        "interfaces": "Custom built intranet and extranet interfaces designed to elevate your business efficiency.",
        "mobile": "Harness mobile technology to tap into new markets and broaden your reach.",
        "automation": "Automate repetitive and time-consuming tasks so your team can focus on what they do best.",
        "cloudhosting": "Cloud hosting services that are SOC 2, ISO 27001, and PCI compliant.",
        "vrar": "Immersive and engaging environments that let your customers interact with your products.",
        "emails": "Automated emails campaigns sent to your subscribers based on their behavior.",
        "optimizely": "Powerful solutions that drive enterprise success and growth.",
        "ai": "Leverage the power of AI to automate tasks and improve your organization's capacities.",
    },
    "about-texts": {
        "why-rollin": "Because we care.",
        "careers": "We are a close-knit team and we're always on the lookout for great talent.",
        "resources": "Understand the technical reasoning behind the technologies we use."
    },
    pages: {
        home: home,
        services: services,
        caseStudies: caseStudies,
        about: about,
        design: design,
        customweb: customweb,
        drupalweb: drupalweb,
        craftweb: craftweb,
        interfaces: interfaces,
        mobile: mobile,
        automation: automation,
        cloudhosting: cloudhosting,
        vrar: vrar,
        emails: emails,
        optimizely: optimizely,
        ai: ai,
    },
    contact: {
        "title": "Let's talk!",
        "description_1": "You have a great project idea and you need a technical partner to execute it.",
        "emphasis_1": "We are here to help you.",
        "description_2": "Fill the form to tell us about your project or send us an email at",
        form: {
            "name": "Name",
            "email": "Email",
            "phone": "Phone number",
            "company": "Company",
            "project": "Project description",
            "budget": "Project budget",
            "how_did": "How did you hear about us?",
            "submit": "Submit",
            "required": "is required",
            "telvalidation": "Phone number must be in the format xxx-xxx-xxxx",
            "emailvalidation": "Please enter a valid email address",
        },
        options: [
            {value: 'Referral', label: 'Referral'},
            {value: 'Google', label: 'Google search'},
            {value: 'LinkedIn', label: 'LinkedIn'},
            {value: 'Dribbble', label: 'Dribbble'},
            {value: 'DesignRush', label: 'DesignRush'},
            {value: 'Reddit', label: 'Reddit'},
            {value: 'Other', label: 'Other'},
        ]
    },
    footer: {
        "ready_to_start": "Ready to start",
        "your_project": "your project",
        "our_team_of_developers": "Our team of developers are here to help you build your next big thing!",
        "contact_us": "Contact us",
        "follow": "Follow us on",
    },
    login: 'Email',
    password: 'Password',
    connect: 'Login'
}

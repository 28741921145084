import {home} from './home'
import {services} from "./services";
import {caseStudies} from "./case-studies";
import {about} from "./about";
import {design} from "./design";
import {customweb} from "./customweb";
import {drupalweb} from "./drupalweb";
import {craftweb} from "./craftweb";
import {interfaces} from "./interfaces";
import {mobile} from "./mobile";
import {automation} from "./automation";
import {vrar} from "./vrar";
import {cloudhosting} from "./cloudhosting";
import {emails} from "./emails";
import {optimizely} from "./optimizely";
import {ai} from "./ai";

export const fr = {
    meta: {
        home: 'Agence de développement Drupal à Québec',
        contact: 'Nous joindre',
        services: 'Nos services',
        automation: 'Services d\'automatisation',
        cloudhosting: 'Hébergement cloud (CI/CD)',
        craftweb: 'Services de développement CraftCMS',
        customweb: 'Services de développement web sur mesure',
        design: 'Services de design et créatifs',
        drupalweb: 'Services de développement Drupal',
        emails: 'Services de courriels automatisés',
        interfaces: 'Services de développement d\'interfaces personnalisées',
        mobile: 'Services de développement d\'applications mobiles',
        vrar: 'Services de développement d\'expériences VR/AR',
        casestudies: 'Études de cas',
        casestudies1: 'Études de cas - Webcampus',
        casestudies2: 'Études de cas - JB Conseil',
        casestudies3: 'Études de cas - Haribo',
        casestudies4: 'Études de cas - NSI Solutions (ACP 005)',
        about: 'À propos',
        careers: 'Carrières',
        whyrollin: 'Pourquoi travailler avec nous?',
        resources: 'Ressources techniques',
        optimizely: 'Optimizely CMS/DXP',
        ai: 'Intelligence Artificielle',
    },
    general: {
        "read_more": "En savoir plus",
        "email": "bonjour",
        "featured": "En vedette",
        "interested": "Vous pourriez aussi être intéressé par...",
        "platforms": "Plateformes",
        "deliverables": "Livrables",
        "techstack": "Technologies",
        "message": "Nous avons bien reçu votre demande, nous vous répondrons rapidement.",
        "error": "Une erreur s'est produite, veuillez réessayer plus tard.",
        "casestudy": "Lire l'étude de cas",
        "notfound": "Introuvable",
        "notfound1": "La page que vous recherchez n'existe pas.",
        "notfound2": "Retour à l'accueil",
        "lastupdate": "Dernière mise à jour",
        "search": "Rechercher...",
        "noresults": "Il n'y a pas de résulat pour votre recherche.",
        "noresults2": "Retour aux ressources.",
    },
    menu: {
        home: 'Accueil',
        services: 'Nos services',
        about: 'À propos',
        casestudies: 'Études de cas',
        design: 'Design & Créatif',
        customweb: 'Développement web',
        drupalweb: 'Développement Drupal',
        craftweb: 'Développement Craft CMS',
        interfaces: 'Interfaces personnalisées',
        mobile: 'Applications mobiles',
        automation: 'Automatisation',
        cloudhosting: 'Infrastructure cloud (CI/CD)',
        vrar: 'Expériences VR/AR',
        emails: 'Courriels automatisés',
        whyrollin: 'Pourquoi Rollin?',
        careers: 'Carrières',
        resources: 'Ressources',
        contact: 'Nous joindre',
        optimizely: 'Optimizely DXP',
        ai: 'Intelligence Artificielle',
        access: 'Accès client',
    },
    "menutexts": {
        "design": "Des designs riches et colorés fondés sur des données qui renforcent le positionnement de votre marque.",
        "customweb": "Services de développement web sur mesure et adaptés à votre réalité.",
        "drupalweb": "Un CMS de niveau entreprise pour la création de solutions numériques vastes et évolutives",
        "craftweb": "Une alternative puissante à WordPress pour les sites web de petite et moyenne envergure.",
        "interfaces": "Des interfaces intranet et extranet sur mesure conçues pour améliorer l'efficacité de votre entreprise.",
        "mobile": "Exploitez la technologie mobile pour atteindre de nouveaux marchés et élargir votre portée.",
        "automation": "Automatisez les tâches répétitives pour que votre équipe puisse se concentrer sur ce qu'elle fait de mieux.",
        "cloudhosting": "Des services d'hébergement conformes aux normes SOC 2, ISO 27001 et PCI.",
        "vrar": "Des environnements immersifs et captivants permettant aux clients d'interagir avec vos produits.",
        "emails": "Campagnes de courriels automatisés envoyés à vos abonnés en fonction de leur comportement.",
        "optimizely": "Des solutions puissantes qui stimulent le succès et la croissance de votre entreprise.",
        "ai": "Exploitez la puissance de l'IA pour automatiser les tâches et améliorer les capacités de votre organization.",
    },
    "about-texts": {
        "why-rollin": "Parce que nous sommes passionnés",
        "careers": "Nous sommes une équipe familiale et toujours à la recherche de nouveaux talents.",
        "resources": "Comprenez le raisonnement technique derrière les technologies que nous utilisons."
    },
    pages: {
        home: home,
        services: services,
        caseStudies: caseStudies,
        about: about,
        design: design,
        customweb: customweb,
        drupalweb: drupalweb,
        craftweb: craftweb,
        interfaces: interfaces,
        mobile: mobile,
        automation: automation,
        cloudhosting: cloudhosting,
        vrar: vrar,
        emails: emails,
        optimizely: optimizely,
        ai: ai
    },
    contact: {
        "title": "Discutons !",
        "description_1": "Vous savez ce que vous désirez entreprendre et vous avez besoin d'un partenaire technique pour le réaliser.",
        "emphasis_1": "Nous sommes là pour vous aider.",
        "description_2": "Expliquez-nous votre projet en remplissant le formulaire ou en nous envoyant un courriel à",
        form: {
            "name": "Nom",
            "email": "Courriel",
            "phone": "Téléphone",
            "company": "Entreprise",
            "project": "Description du projet",
            "budget": "Budget",
            "how_did": "Comment avez-vous entendu parler de nous?",
            "submit": "Envoyer",
            "required": "est requis",
            "telvalidation": "Le téléphone doit être dans le format xxx-xxx-xxxx",
            "emailvalidation": "Veuillez entrer un courriel valide",
        },
        options: [
            {value: 'Referral', label: 'Référence'},
            {value: 'Google', label: 'Recherche Google'},
            {value: 'LinkedIn', label: 'LinkedIn'},
            {value: 'Dribbble', label: 'Dribbble'},
            {value: 'DesignRush', label: 'DesignRush'},
            {value: 'Reddit', label: 'Reddit'},
            {value: 'Other', label: 'Autre'},
        ]
    },
    footer: {
        "ready_to_start": "Prêt à commencer",
        "your_project": "votre projet",
        "our_team_of_developers": "Notre équipe de développeurs est là pour vous aider à construire votre prochain grand projet !",
        "contact_us": "Nous joindre",
        "follow": "Suivez-nous sur",
    },
    login: 'Courriel',
    password: 'Mot de passe',
    connect: 'Connexion'
}
